import React from 'react'
import wedding from '../../../assets/images/landingImages/wedding.png'
import event from '../../../assets/images/landingImages/event.png'
import corporate from '../../../assets/images/landingImages/corporate.png'
import parties from '../../../assets/images/landingImages/parties.png'
import partyImg from '../../../assets/images/photo_banner/headshackgif.gif'
import corp1 from '../../../assets/images/photo_banner/corporat1gif.gif'
import corp2 from '../../../assets/images/photo_banner/corporate2gif.gif'
import animated1 from '../../../assets/images/gifimg1.gif'
import animated2 from '../../../assets/images/gifimg2.gif'
import LandingLayout from './Layout/LandingLayout'

const EventsInfo = () => {
    return (
        <LandingLayout>
        <div>
            <div className="what-is-it section-gap">
                <div className="container">
                    <h1 className="title">
                        <div className="imotic">
                            <img src={wedding} alt="Image" />
                        </div>
                        Weddings</h1>
                    <p className="text-content">Why would you choose Phramebooth for you wedding?</p>
                </div>
            </div>
            <div className="wedding-detail">
                <div className="container">
                    <div className="animated-box">
                        <div className="animated-box-image">
                            <img src={animated1} alt="Image" />
                        </div>
                        <div className="animated-box-content spec-info">
                            <ul className="list">
                                <li><span>1</span><b>Phramebooth will up the fun factor for your event.</b>  It will bring you and your guest beautiful memories.</li>
                                <li><span>2</span><b>Your guest will love to take home mementos in digital or print form.</b> Guest can hang on to a great photo from touchscreen.</li>
                                <li><span>3</span><b>There is to much for the hired photographer to capture.</b> Phramebooth is like getting an set of wedding photos for a fraction of the cost.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="pharmabooth-coupen">
                        <h3 className="pharma-title">Fun Places to use Phramebooth: </h3>
                        <ul className="pharma-list">
                            <li>Bridal Showers</li>
                            <li>Bachelor or Bachelorette Parties</li>
                            <li>Engagement Party</li>
                            <li>Rehearsal Dinners</li>
                            <li>Honeymoons</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="special-events">
                <div className="what-is-it section-gap">
                    <div className="container">
                        <h1 className="title">
                            <div className="imotic">
                                <img src={event} alt="Image" />
                            </div>
                            Special Events</h1>
                    </div>
                </div>
                <div className="wedding-detail">
                    <div className="container">
                        <div className="animated-box">
                            <div className="animated-box-image">
                                <img src={animated2} alt="Image" />
                            </div>
                            <div className="animated-box-content">
                                <ul className="list">
                                    <li>Phramebooth is the stunning party photo booth, so whatever you are having we will be there.</li>
                                    <li><b>Every photo can be customized </b> with digital layouts, creative filters, and green screen back grounds.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="what-is-it section-gap">
                <div className="container">
                    <h1 className="title">
                        <div className="imotic">
                            <img src={corporate} alt="Image" />
                        </div>
                        Corporate Events </h1>
                </div>
            </div>
            <div className="wedding-detail">
                <div className="container">
                    <div className="animated-box">
                        <div className="animated-box-image spec-box-image">
                            <img src={corp1} alt="Image" />
                            <img src={corp2} alt="Image" />
                        </div>
                        <div className="animated-box-content">
                            <ul className="list">
                                <li>Be the company everyone is talking about using the Phramebooth.</li>
                                <li><b>Give your guest an unforgettable experience.</b> Treat your employees to the celebration they deserve.</li>
                                <li>Phramebooth experience will be the most memorable part of your event. But while guest are snapping and sharing photos you can gather data with custom surveys.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="special-events">
                <div className="what-is-it section-gap">
                    <div className="container">
                        <h1 className="title">
                            <div className="imotic">
                                <img src={parties} alt="Image" />
                            </div>
                            Birthday Parties</h1>
                    </div>
                </div>
                <div className="wedding-detail">
                    <div className="container">
                        <div className="animated-box">
                            <div className="animated-box-image">
                                <img src={partyImg} alt="Image" />
                            </div>
                            <div className="animated-box-content">
                                <ul className="list">
                                    <li><b>There is no better way to dial up the fun at your next birthday bash than taking photos.</b></li>
                                    <li>After all anyone from ages 1 to 99 love hamming it for a photo. Fun props to inspire, and engage your party guest. For parties we have the perfect themes for any age. </li>
                                    <li> All we need is your inspiration and imagination.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </LandingLayout>
    )
}

export default EventsInfo
