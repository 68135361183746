import React from 'react'
import SEO from '../components/seo'
import EventsInfo from '../Views/Home/LandigPage/EventsInfo'

const EventsPage = () => {	
	
	return (
		<>
			<SEO title="events" />
				<EventsInfo />
		</>
	)
}

export default EventsPage
